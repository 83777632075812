<template>
  <div class="h-screen w-full flex items-center justify-center p-2">
    <!-- Loading Overlay -->
    <div
      v-if="!scenarioReady"
      class="absolute inset-0 bg-gray-900/50 backdrop-blur-sm flex items-center justify-center z-50"
    >
      <div class="text-white text-center items-center content-center">
        <p class="text-xl font-semibold">Loading scenario...</p>
        <LoaderCircle class="h-8 w-8 animate-spin mx-auto" />
      </div>
    </div>

    <div
      v-if="!showSummary"
      class="flex flex-col h-full w-full mx-auto border rounded-lg overflow-hidden"
    >
      <div ref="chatArea" class="flex-grow p-4 overflow-auto">
        <div
          v-for="(message, index) in messages"
          :key="index"
          :class="['mb-4', message.isUser ? 'text-right' : 'text-left']"
        >
          <span
            :class="[
              'inline-block p-2 rounded-lg prose',
              message.isUser
                ? 'bg-blue-500 text-white'
                : 'bg-gray-200 text-gray-800',
            ]"
            v-html="message.html"
          >
          </span>
        </div>
      </div>
      <div class="border-t p-2 pb-1 flex items-center">
        <input
          type="text"
          v-model="inputText"
          @keypress.enter="handleSend"
          placeholder="Type a message..."
          class="flex-grow mr-2 px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <button
          @click="handleSend"
          class="mr-2 bg-blue-500 text-white p-2 rounded-lg enabled:hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:bg-gray-200 disabled:text-gray-800"
          :disabled="pending"
        >
          <LoaderCircle v-if="pending" class="h-4 w-4 animate-spin" />
          <Send v-else class="h-4 w-4" />

          <span class="sr-only">Send message</span>
        </button>
        <button
          v-if="!isMobileDevice"
          @click="toggleListening"
          :class="[
            'p-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500',
            isListening
              ? 'bg-red-500 text-white hover:bg-red-600'
              : 'bg-gray-200 text-gray-800 hover:bg-gray-300',
          ]"
        >
          <component :is="isListening ? MicOff : Mic" class="h-4 w-4" />
          <span class="sr-only">{{
            isListening ? "Stop voice input" : "Start voice input"
          }}</span>
        </button>
        <button
          v-if="score"
          @click="showSummary = true"
          class="ml-2 bg-blue-500 text-white p-2 rounded-lg enabled:hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:bg-gray-200 disabled:text-gray-800"
          :disabled="pending"
        >
          <span>View Summary</span>
        </button>
      </div>
      <div class="mx-auto">
        <a
          href="https://aiscenariocreator.com/"
          target="_blank"
          rel="noopener noreferrer"
          class="text-blue-500 hover:underline text-sm"
          >Powered by AI Scenario Creator</a
        >
      </div>
    </div>
    <div
      v-else
      class="flex flex-col h-full w-full mx-auto border rounded-lg overflow-hidden"
    >
      <div class="flex-grow p-4 overflow-auto">
        <div class="mb-4 text-left">
          <span
            class="inline-block p-2 rounded-sm prose bg-gray-200 text-gray-800"
            v-html="summary"
          >
          </span>
        </div>
        <div class="mb-4 text-left">
          <span
            class="inline-block p-2 rounded-sm prose bg-gray-200 text-gray-800"
            >Overall Score: {{ score }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, nextTick, watch } from "vue";
//import the route so we can get the id parama
import { useRoute } from "vue-router";
import { Mic, MicOff, Send, LoaderCircle } from "lucide-vue-next";
import axios from "axios";
import { marked } from "marked";

const route = useRoute();

const messages = ref([]);
const inputText = ref("");
const isListening = ref(false);
const recognition = ref(null);
const chatArea = ref(null);
const threadId = ref(null);
const score = ref(null);
const summary = ref(null);
const complete = ref(false);
const pending = ref(true);
const showSummary = ref(false);
const scenarioReady = ref(false);
const publicId = ref(route.params.id);
const isMobileDevice = ref(false);
const ws = ref(null);

onMounted(() => {
  //check if the browser is running on a mobile device
  if (/Mobi|Android/i.test(navigator.userAgent)) {
    isMobileDevice.value = true;
  }

  if ("SpeechRecognition" in window || "webkitSpeechRecognition" in window) {
    recognition.value = new (window.SpeechRecognition ||
      window.webkitSpeechRecognition)();
    recognition.value.continuous = true;
    recognition.value.interimResults = true;

    recognition.value.onresult = (event) => {
      const transcript = Array.from(event.results)
        .map((result) => result[0].transcript)
        .join("");
      inputText.value = transcript;
    };
  }
  //get a threadId from the backend
  axios.get("/api/chat/" + publicId.value).then((response) => {
    threadId.value = response.data.threadId;
    createWebSocket();
    //create first message
    messages.value.push({
      text: response.data.message,
      isUser: false,
      html: marked(response.data.message),
    });
    pending.value = false;
    scenarioReady.value = true;
    //post message to the parent window that we are ready
    window.parent.postMessage({ message: "started" }, "*");
  });
});

onUnmounted(() => {
  if (recognition.value) {
    recognition.value.stop();
  }
});

const toggleListening = () => {
  if (isListening.value) {
    recognition.value?.stop();
  } else {
    recognition.value?.start();
  }
  isListening.value = !isListening.value;
};

const handleSend = async () => {
  if (inputText.value.trim()) {
    //turn off the mic if it is on
    if (isListening.value) {
      recognition.value?.stop();
      isListening.value = false;
    }
    pending.value = true;
    messages.value.push({
      text: inputText.value,
      isUser: true,
      html: inputText.value,
    });
    // Push the response to the messages array
    messages.value.push({
      text: "",
      isUser: false,
      html: marked(""),
    });
    const sentMessage = inputText.value;
    inputText.value = "";
    const response = await axios.post("/api/chat/" + publicId.value, {
      threadId: threadId.value,
      message: sentMessage,
    });
    pending.value = false;
    /*
    if (response.data.response.complete) {
      complete.value = true;
      window.parent.postMessage({ message: "completed" }, "*");
      //call backend to get score
      const scoreResponse = await axios.post("/api/score/" + publicId.value, {
        threadId: threadId.value,
      });
      //convert scoreResponse.message to json
      const scoreAndSummary = JSON.parse(scoreResponse.data.response.message);
      score.value = scoreAndSummary.overall_score;
      summary.value = marked(scoreAndSummary.message);
      window.parent.postMessage(
        { message: "score", score: scoreAndSummary.overall_score },
        "*"
      );
    }
      */
  }
};

const createWebSocket = () => {
  console.log("Creating websocket");
  //"ws://localhost:5000/ws/chat/" + threadId.value
  const wsProtocol = window.location.protocol === "https:" ? "wss" : "ws";
  ws.value = new WebSocket(
    wsProtocol + "://" + window.location.host + "/ws/chat/" + threadId.value
  );
  ws.value.onopen = () => {
    console.log("Websocket connected");
  };
  ws.value.onmessage = (event) => {
    const data = JSON.parse(event.data);
    if (data.event === "delta") {
      //append the text to the last message
      messages.value[messages.value.length - 1].text += data.message;
      messages.value[messages.value.length - 1].html = marked(
        messages.value[messages.value.length - 1].text
      );
      /*
      messages.value.push({
        text: data.message,
        isUser: false,
        html: marked(data.message),
      });
      */
    }
  };
};

const scrollToBottom = () => {
  nextTick(() => {
    if (chatArea.value) {
      chatArea.value.scrollTop = chatArea.value.scrollHeight;
    }
  });
};

// Watch for changes in messages and scroll to bottom
watch(messages, scrollToBottom, { deep: true });
</script>
