<!-- frontend/src/App.vue -->
<template>
  <div>
    <router-view />
  </div>
</template>
<script setup>
import { onMounted } from "vue";
import posthog from "posthog-js";

onMounted(() => {
  if (process.env.NODE_ENV === "production") {
    posthog.init("phc_6957KAscs0MKBITnfkz3xJwhyv1xbNb6YNol0ZSdkC", {
      api_host: "https://app.posthog.com",
    });
  }
});
</script>
<style></style>
