<template>
  <div
    class="min-h-screen p-6 bg-gradient-to-br from-gray-900 via-blue-900 to-purple-900"
  >
    <!-- Jumbo Welcome Message -->
    <div class="max-w-4xl mx-auto">
      <div
        class="flex flex-col justify-between items-center mb-6 text-gray-300"
      >
        <h2 class="text-3xl font-semibold text-cyan-300 mb-2">
          Welcome to the Scenario Creator [BETA]
        </h2>
        <p class="text-gray-300 mb-2">
          Click on the "Create New Scenario" button to get started.
        </p>
        <p class="text-gray-300 mb-2">
          If you embed the downloaded file into Storyline or Captivate it will
          set three variables in your course.
        </p>
        <ol class="list-decimal">
          <li><code>chat_started [boolean]</code></li>
          <li><code>chat_completed [boolean]</code></li>
          <li>
            <code>chat_score [number]</code
            ><span class="text-xs">(a few seconds after chat_completed)</span>
          </li>
        </ol>
      </div>
    </div>
    <div class="max-w-4xl mx-auto">
      <div class="flex justify-between items-center mb-6">
        <a
          href="/create"
          class="flex content-center px-4 py-2 bg-green-500 hover:bg-green-600 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
        >
          <MessageSquarePlus class="mr-2 w-4 h-4 mt-1" /><span
            >Create New Scenario</span
          >
        </a>
        <button
          @click="isOpen = true"
          class="flex content-center px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          Get Updates
        </button>
      </div>

      <div class="grid gap-2">
        <div
          v-for="scenario in scenarios"
          :key="scenario.id"
          class="bg-gray-800/80 backdrop-blur-sm text-white border border-gray-700 rounded-lg shadow-lg overflow-hidden"
        >
          <div class="p-4">
            <h2 class="text-xl font-semibold text-cyan-300 mb-2">
              {{ scenario.title }}
            </h2>
            <p class="text-gray-300 mb-2 line-clamp-2">
              {{ scenario.json_data["Scenario Overview"].overview }}
            </p>

            <div class="mt-4 flex justify-end space-x-2">
              <div
                class="flex justify-between space-x-2 items-center text-sm text-gray-400"
              >
                <span>{{ scenario.json_data["Scenario Setting"].theme }}</span>
                <span>{{
                  scenario.json_data["Scenario Setting"].timeLength
                }}</span>
              </div>
              <button
                @click="viewScenario(scenario.public_id)"
                class="px-3 py-1 bg-blue-500 hover:bg-blue-600 text-white rounded flex items-center"
              >
                <eye-icon class="w-4 h-4 mr-2" />
                View
              </button>
              <a
                :href="`/api/download/${scenario.public_id}`"
                @click="downloadScenario(scenario.public_id)"
                class="px-3 py-1 bg-blue-500 hover:bg-blue-600 text-white rounded flex items-center"
              >
                <download-icon class="w-4 h-4 mr-2" /> Download</a
              >

              <button
                @click="editScenario(scenario.public_id)"
                class="px-3 py-1 bg-green-500 hover:bg-green-600 text-white rounded flex items-center"
              >
                <pen-square-icon class="w-4 h-4 mr-2" />
                Edit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- sign up modal -->
    <div
      v-if="isOpen"
      class="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50"
    >
      <div
        class="bg-white rounded-lg overflow-hidden shadow-xl max-w-md w-full p-6 relative"
      >
        <button
          @click="onCloseModal"
          class="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        >
          <x-icon />
        </button>
        <Signup @closeModal="onCloseModal" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import {
  EyeIcon,
  PenSquareIcon,
  MessageSquarePlus,
  DownloadIcon,
  XIcon,
} from "lucide-vue-next";
import axios from "axios";
import posthog from "posthog-js";
import Signup from "./Signup.vue";

const scenarios = ref(null);
const isOpen = ref(false);

onMounted(async () => {
  // Fetch scenarios from the backend
  const response = await axios.get("/api/assistants");
  scenarios.value = response.data.data;
});

const viewScenario = (publicId) => {
  if (process.env.NODE_ENV === "production") {
    posthog.capture("view_scenario", {
      public_id: publicId,
    });
  }

  window.location.href = "/chat/" + publicId;
};

const downloadScenario = (publicId) => {
  if (process.env.NODE_ENV === "production") {
    posthog.capture("download_scenario", {
      public_id: publicId,
    });
  }
};

const editScenario = (publicId) => {
  if (process.env.NODE_ENV === "production") {
    posthog.capture("edit_scenario", {
      public_id: publicId,
    });
  }
  window.location.href = "/edit/" + publicId;
};

const onCloseModal = () => {
  isOpen.value = false;
};
</script>

<style scoped>
/* Add any component-specific styles here if needed */
</style>
